import React, { useMemo } from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { AppRoute } from 'common/routing/AppRoute';
import { useVersionContext } from 'common/hooks/useVersion/useVersion';

import { useStyles } from './Breadcrumbs.styles';
import { breadcrumbsData, breadcrumbsDataVerB } from './Breadcrumbs.data';

export const Breadcrumbs = () => {
  const styles = useStyles();
  const { moveVer } = useVersionContext();
  const {
    push,
    location: { pathname },
  } = useHistory();

  const breadcrumbsDataCorrectVersion = useMemo(() => {
    return moveVer === 'A' ? breadcrumbsData : breadcrumbsDataVerB;
  }, [moveVer]);

  const breadcrumbsItems = useMemo(() => {
    const items: string[] = [];
    breadcrumbsDataCorrectVersion.forEach((crumb) => pathname.includes(crumb.path) && items.push(crumb.breadcrumb));
    return items;
  }, [pathname, breadcrumbsDataCorrectVersion]);

  const breadcrumbsItemsUnique = useMemo(() => [...new Set(breadcrumbsItems)], [breadcrumbsItems]);

  return (
    <MuiBreadcrumbs className={styles.breadcrumbs}>
      <Typography onClick={() => push(AppRoute.home)} className={styles.home}>
        services
      </Typography>

      {breadcrumbsItemsUnique.map((item, index) => (
        <Typography key={index}>{item}</Typography>
      ))}
    </MuiBreadcrumbs>
  );
};
