import React from 'react';
import { AppBar, Box, Toolbar, Typography, Link } from '@material-ui/core';
import clsx from 'clsx';

import logo from 'common/assets/images/logo.png';
import { TelephoneIcon } from 'common/assets/icons/TelephoneIcon';
import { useFormState } from 'common/hooks/useFormState/useFormState';

import { useStyles } from './Navigation.styles';

export const Navigation = () => {
  const styles = useStyles();
  const { isPartner, partnerData } = useFormState();

  return (
    <AppBar position="static" color="transparent" component="header" id="header" elevation={0}>
      <Toolbar className={styles.navbar}>
        {!isPartner && (
          <Link href="https://www.collegehunkshaulingjunk.com" className={styles.logo}>
            <img src={logo} alt="College H.U.N.K.S. logo" className={styles.img} />
          </Link>
        )}
        {isPartner && (
          <img src={partnerData?.logoUrl || ''} alt="College H.U.N.K.S. logo" className={styles.partnerLogo} />
        )}

        <Box ml="auto">
          <Typography
            component="a"
            href={isPartner ? 'tel:+1-813-853-4623' : 'tel:+1-833-626-1165'}
            variant="h5"
            color="inherit"
            className={clsx(styles.phoneNumber, isPartner && styles.partnerPhoneNumber)}
          >
            <TelephoneIcon className={styles.icon} />
            {isPartner ? '(813) 853-4623' : '(833) 626-1165'}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
