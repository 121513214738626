import { AppRoute, JunkRemovalRoute, LaborRoute, MovingRoute } from 'common/routing/AppRoute';
import { MovingRoute as MovingRouteVerB } from 'bMove/routing/AppRoute';

// routes names are listed in [CHHJ-463]
export const breadcrumbsData = [
  {
    path: `${AppRoute.junkRemoval}`,
    breadcrumb: 'junk removal',
  },
  {
    path: `${AppRoute.moving}`,
    breadcrumb: 'moving',
  },
  {
    path: `${AppRoute.labor}`,
    breadcrumb: 'labor',
  },
  {
    path: `${JunkRemovalRoute.step1}`,
    breadcrumb: 'your location',
  },
  {
    path: `${JunkRemovalRoute.step2}`,
    breadcrumb: 'contact information',
  },
  {
    path: `${JunkRemovalRoute.step3}`,
    breadcrumb: 'job date',
  },
  {
    path: `${JunkRemovalRoute.step4}`,
    breadcrumb: 'number of items',
  },
  {
    path: `${JunkRemovalRoute.step5}`,
    breadcrumb: 'pricing',
  },
  {
    path: `${JunkRemovalRoute.step6}`,
    breadcrumb: 'summary',
  },
  {
    path: `${MovingRoute.step1}`,
    breadcrumb: 'moving from',
  },
  {
    path: `${MovingRoute.step2}`,
    breadcrumb: 'moving to',
  },
  {
    path: `${MovingRoute.step3}`,
    breadcrumb: 'special items',
  },
  {
    path: `${MovingRoute.step4}`,
    breadcrumb: 'job date',
  },
  {
    path: `${MovingRoute.step5}`,
    breadcrumb: 'contact information',
  },
  {
    path: `${MovingRoute.step6}`,
    breadcrumb: 'summary',
  },
  {
    path: `${LaborRoute.step1}`,
    breadcrumb: 'your location',
  },
  {
    path: `${LaborRoute.step2}`,
    breadcrumb: 'contact information',
  },
  {
    path: `${LaborRoute.step3}`,
    breadcrumb: 'summary',
  },
];

export const breadcrumbsDataVerB = [
  {
    path: `${AppRoute.junkRemoval}`,
    breadcrumb: 'junk removal',
  },
  {
    path: `${AppRoute.moving}`,
    breadcrumb: 'moving',
  },
  {
    path: `${AppRoute.labor}`,
    breadcrumb: 'labor',
  },
  {
    path: `${JunkRemovalRoute.step1}`,
    breadcrumb: 'your location',
  },
  {
    path: `${JunkRemovalRoute.step2}`,
    breadcrumb: 'job date',
  },
  {
    path: `${JunkRemovalRoute.step3}`,
    breadcrumb: 'contact information',
  },
  {
    path: `${JunkRemovalRoute.step4}`,
    breadcrumb: 'number of items',
  },
  {
    path: `${JunkRemovalRoute.step5}`,
    breadcrumb: 'pricing',
  },
  {
    path: `${JunkRemovalRoute.step6}`,
    breadcrumb: 'summary',
  },
  {
    path: `${MovingRouteVerB.step1}`,
    breadcrumb: 'moving from',
  },
  {
    path: `${MovingRouteVerB.step2}`,
    breadcrumb: 'moving to',
  },
  {
    path: `${MovingRouteVerB.step3}`,
    breadcrumb: 'special items',
  },
  {
    path: `${MovingRouteVerB.step4}`,
    breadcrumb: 'job date',
  },
  {
    path: `${MovingRouteVerB.step5}`,
    breadcrumb: 'contact information',
  },
  {
    path: `${MovingRouteVerB.step6}`,
    breadcrumb: 'job time',
  },
  {
    path: `${MovingRouteVerB.step7}`,
    breadcrumb: 'pricing',
  },
  {
    path: `${MovingRouteVerB.step8}`,
    breadcrumb: 'summary',
  },
  {
    path: `${MovingRouteVerB.prepayment}`,
    breadcrumb: 'prepayment',
  },
  {
    path: `${LaborRoute.step1}`,
    breadcrumb: 'your location',
  },
  {
    path: `${LaborRoute.step2}`,
    breadcrumb: 'contact information',
  },
  {
    path: `${LaborRoute.step3}`,
    breadcrumb: 'summary',
  },
];
