import React from 'react';
import { Typography, Hidden } from '@material-ui/core';

import aboutHunks from 'common/assets/images/aboutHunks.jpg';
import { Container } from 'common/app/home/container/Container';
import { LoadedImage } from 'common/ui/loadedImage/LoadedImage';

import { useStyles } from './AboutHunks.styles';

export const AboutHunks = () => {
  const styles = useStyles();

  return (
    <Container className={styles.container}>
      <Hidden smUp>
        <Typography variant="h3" className={styles.title}>
          About the H.U.N.K.S
        </Typography>
      </Hidden>

      <div className={styles.flexContainer}>
        <div className={styles.imgContainer}>
          <LoadedImage src={aboutHunks} alt="College H.U.N.K.S" className={styles.img} />
        </div>

        <div>
          <Hidden xsDown>
            <Typography variant="h3" className={styles.title}>
              About the H.U.N.K.S
            </Typography>
          </Hidden>

          <Typography>
            Is the hassle of moving weighing you down? Let our friendly HUNKS take care of the heavy lifting for you.
            College HUNKS Hauling Junk & Moving® is proud to offer stress-free moving and junk removal services that
            will give you peace of mind.
            <br />
            <br />
            Our hard-working HUNKS have the lowest damage claim rate in the business and can take care of the entire
            process for you, from packing to unpacking. Our expert service is specially designed to make your move a
            fun, pleasant experience.
          </Typography>
        </div>
      </div>
    </Container>
  );
};
