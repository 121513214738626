import { Button } from '@chhjpackages/components';
import { Typography, Box } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import { useStyles } from './ServiceCard.styles';
import { ServiceCardProps } from './ServiceCard.types';

export const ServiceCard = ({ onClick, icon, title, subTitle }: ServiceCardProps) => {
  const styles = useStyles();

  return (
    <div className={styles.box} onClick={onClick}>
      <div className={styles.pseudoElementContainer}>
        <div className={styles.titleWithIcon}>
          <div className={styles.iconContainer}>{icon}</div>
          <Typography variant="h4" className={styles.title}>
            {title}
          </Typography>
        </div>

        <Box mt={2} />
        <Typography className={clsx(styles.subTitle, title)}>{subTitle}</Typography>

        <Box mt="auto">
          <Button fullWidth size="large" buttonType="twoTone" className={styles.button}>
            Choose
          </Button>
        </Box>
      </div>
    </div>
  );
};
