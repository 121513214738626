import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  box: {
    position: 'relative',
    zIndex: 1,

    borderRadius: 4,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: 'transparent',
      transition: 'box-shadow .2s ease-in-out',
      boxShadow: `0 0 0 1px ${colors.grey100}`,
      borderRadius: 4,
    },

    [theme.breakpoints.up('sm')]: {
      padding: 24,
    },

    [theme.breakpoints.up('md')]: {
      '&:hover::after': {
        boxShadow: `0 0 0 2px ${colors.secondary.main}`,
      },

      '&:hover $pseudoElementContainer::before': {
        opacity: 1,
      },
      '&:hover $iconContainer': {
        backgroundColor: colors.basic.white,
      },

      '&:hover $button button': {
        backgroundColor: colors.primary.light,
      },
    },
  },
  pseudoElementContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',

    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: -1,
      top: -8,
      left: -8,
      bottom: -40,
      right: -40,

      backgroundColor: colors.secondary.light,
      transition: 'opacity .2s ease-in-out',
      borderRadius: 4,
      opacity: 0,
    },
  },
  title: {
    color: colors.grey190,

    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },

    [theme.breakpoints.up('md')]: {
      ...theme.typography.h3,
      color: colors.grey190,
    },
  },
  subTitle: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
      maxWidth: 214,
      margin: '0 auto',
    },
  },
  titleWithIcon: {
    display: 'flex',
    alignItems: 'center',

    '& > *:first-child': {
      marginRight: 16,
    },

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',

      '& > *:first-child': {
        marginRight: 0,
        marginBottom: 16,
      },
    },
  },
  iconContainer: {
    backgroundColor: colors.secondary.light,
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color .2s ease-in-out',

    '& svg': {
      fontSize: '32px',
    },

    [theme.breakpoints.up('sm')]: {
      width: 92,
      height: 92,

      '& svg': {
        fontSize: '80px',
      },
    },
  },

  button: {
    marginTop: 16,

    [theme.breakpoints.up('md')]: {
      marginTop: 32,
    },
  },
}));
